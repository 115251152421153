import '../styles/footer.css'

export function Footer() {
    return (
        <div className="footer">
            <div className="name">
                <h3>&copy; Ibrahim Abdulkarim Portfolio Page 2023</h3>
            </div>
        </div>
    )
}
